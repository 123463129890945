import React, { Fragment, useState } from 'react';
import './App.css';

const App = () => {
  const date = new Date();
	const year = date.getFullYear();

  const [contact, setContact] = useState({
    email: ""
  });

  const handleChange = (event) => {
    console.log(event.target.name)
    setContact({ ...contact, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setContact({ email: "" });
  }

  return (
    <Fragment>
      <header className="w-100 bg-white sm-position-relative">
        <div className="navbar-default">
          <div className="top-search bg-theme-solid">
            <div className="container">
              <form className="search-form" action="search.html" method="GET" accept-charset="utf-8">
                <div className="input-group">
                  <span className="input-group-addon cursor-pointer">
                    <button className="search-form_submit fas fa-search font-size18 text-white" type="submit"></button>
                  </span>
                  <input type="text" className="search-form_input form-control" name="s" autocomplete="off" placeholder="Type &amp; hit enter..." />
                  <span className="input-group-addon close-search"><i className="fas fa-times mt-2"></i></span>
                </div>
              </form>
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-12">
                <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="navbar-header navbar-header-custom">
                        <a href="/" className="navbar-brand">
                            <img id="logo" src="/img/logos/ObjectiveBitsLogo.svg" alt="logo" width={300}/>
                        </a>
                    </div>

                    <div className="navbar-toggler dark"></div>

                    {/*<ul className="navbar-nav ml-auto" id="nav">*/}
                    {/*  <li><a href="javascript:void(0)">Services</a>*/}
                    {/*    <ul>*/}
                    {/*      <li><a href="service-1.html">Web Solutions</a></li>*/}
                    {/*      <li><a href="service-2.html">Mobile Development</a></li>*/}
                    {/*    </ul>*/}
                    {/*  </li>*/}
                    {/*  <li><a href="contact.html">Sign In</a></li>*/}
                    {/*</ul>*/}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      
      <div className="main-banner-style05 bg-white">
        <div className="home_bubble">
          <div className="square-shape2"></div>
          <div className="bubble b_two d-none d-md-block"></div>
          <div className="bubble b_three"></div>
          <div className="bubble b_four d-none d-sm-block"></div>
          <div className="square-shape1 d-none d-sm-block"></div>
          <div className="bubble b_six d-none d-sm-block"></div>
          <div className="triangle-shape1 d-none d-sm-block"></div>
          <div className="triangle-shape2 d-none d-sm-block"></div>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="header-text" style={{"paddingTop": "4em"}}>
                <h1 className="wow fadeInUp" style={{"fontSize": "48px", "fontWeight": "500"}} data-wow-delay=".1s">Developing every bit with you in mind.</h1>
                {/* <p className="small-title w-90 w-md-75 w-xl-65 wow fadeInUp mx-auto mb-2-2" data-wow-delay=".2s">We are developers mixed with designers and a dash of crazy for good measure. We live for the bits.</p>
                <div className="wow fadeInUp story-video" data-wow-delay=".4s">
                  <a href="#!" className="butn style-one green ml-1 my-1 my-sm-0">Learn More</a>
                  <a href="#!" className="butn style-one orange ml-1 my-1 my-sm-0">Contact Us</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="banner-content-img wow fadeInUp" data-wow-delay=".2s">
          <img src="img/main.svg" alt="" />
        </div>
      </div>

      <section>
              <div class="container pt-10 pt-sm-14 pt-md-20 pt-xl-24">
  
                  <div class="w-md-85 w-lg-65 w-xl-50 text-center mx-auto mb-6 mb-lg-8">
  
                      <div class="badge badge-pill badge-primary-soft mb-3">
                          <span>What we do</span>
                      </div>
  
                      <h2 class="mb-0">Take a bit of us with you</h2>
                  </div>
  
                  <div class="row">
  
                      <div class="col-md-6 col-lg-6 mb-5 mb-lg-0">
                          <div class="card card-style03">
                              <div class="card-body">
                                  <span class="mb-2-6 d-block"><img src="img/web-development.svg" alt="..." class="w-50" /></span>
                                  <h3 class="h5 mb-3">Web Design and Development</h3>
                                  <p class="display-30">You may not know this but most online lookers base their first impression and main opinion of a company on how their website looks...</p>
                                  {/* <a href="#!" class="display-30">more<i class="ti-arrow-right align-middle ml-2"></i></a> */}
                              </div>
                          </div>
                      </div>
  
                      <div class="col-md-6 col-lg-6 mb-5 mb-lg-0">
                          <div class="card card-style03">
                              <div class="card-body">
                                  <span class="mb-2-6 d-block"><img src="img/mobile-development.svg" alt="..." class="w-50" /></span>
                                  <h3 class="h5 mb-3">Mobile App Development</h3>
                                  <p class="display-30">Everyone who’s anyone has or wants a great mobile app to represent their organization. Question is, where can you get one?</p>
                                  {/* <a href="#!" class="display-30">more<i class="ti-arrow-right align-middle ml-2"></i></a> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section class="bg-grey">
            <div class="container">

                <div class="w-md-75 w-xl-50 text-center mx-md-auto mb-5">
                    <h2 class="h1">How do people see you?</h2>
                    <p>What do people see when they visit your site? If beauty is in the eye of the beholder, then having a great looking site is important. With our custom designed sites, our focus is to enhance the vision you have for your organization and develop a beautiful, responsive site that makes a lasting impression.</p>
                </div>
            </div>
        </section>

        <section class="parallax theme-overlay-solid" data-overlay-dark="85" data-background="img/bg/bg-01.jpg">
            <div class="container">

                <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-6">
                    <h2 class="line-title white">A bit about us</h2>
                </div>

                <div class="row">
                    <div class="col-md-6 col-lg-4 mb-5 mb-md-6">
                        <div class="media">
                            <i class="ti-pencil-alt text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">Strategy</h3>
                                <p class="mb-0 text-white-90 font-weight-300">Our strategy is simple, make you more efficient. With Obbi Cloud, you'll efficiently manage the important parts of your organization and have more time to focus on your customer.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-5 mb-md-6">
                        <div class="media">
                            <i class="ti-ruler-alt-2 text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">UI / UX</h3>
                                <p class="mb-0 text-white-90 font-weight-300">If your customers find it difficult to work with you, they won't. We build interfaces with a strict emphasis on improving the usability and accessibility of your product.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-5 mb-md-6">
                        <div class="media">
                            <i class="ti-layers text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">Design</h3>
                                <p class="mb-0 text-white-90 font-weight-300">We create with design in mind. From mobile and web, to watch, tv and beyond, we have the ingenuity for vision and know how to make it come to life.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-5 mb-md-6 mb-lg-0">
                        <div class="media">
                            <i class="ti-bar-chart text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">Technology</h3>
                                <p class="mb-0 text-white-90 font-weight-300">Technology is our middle name. Personally and professionally, we're passionate about new trends and paradigms and try to stay on the leading edge.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-5 mb-md-0">
                        <div class="media">
                            <i class="ti-files text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">Quality</h3>
                                <p class="mb-0 text-white-90 font-weight-300">We've worked in the software industry for a long time, long enough to know the difference between quality and crap. We strive for excellence in quality and we settle for nothing less.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="media">
                            <i class="ti-server text-white mr-2-0 display-20"></i>
                            <div class="media-body">
                                <h3 class="h5 text-white font-weight-500 mb-3">Secure</h3>
                                <p class="mb-0 text-white-90 font-weight-300">We write our code to be secure, build our servers to be secure, run our company and processes to be secure, then to make sure we haven't slipped, we attack from the outside and try to hack our way back in.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

          {/* <section class="slow-redirect">
            <div class="container">
              <div class="w-md-85 w-lg-65 w-xl-50 text-center mx-auto mb-6 mb-lg-8">
                <div class="badge badge-pill badge-primary-soft mb-3">
                    <span>How it works</span>
                </div>

                <h2 class="mb-0">A bit about the workflow</h2>
              </div>

              <div class="row mb-2-9 mb-md-6 mb-lg-9 align-items-center flex-row-reverse" id="step1">
                  <div class="col-lg-6 col-md-12 text-center text-lg-right mb-4 mb-lg-0">
                      <img src="img/content/content-07." alt="..." />
                  </div>
                  <div class="col-lg-6 col-md-12">
                      <div class="pr-lg-5">
                          <span class="icon-circle green mb-4"><span class="text">01</span></span>
                          <h3 class="text-color-md-blue mb-4">Investigate and analize your needs</h3>
                          <p></p>
                      </div>
                  </div>
              </div>

                <div class="row mb-2-9 mb-md-6 mb-lg-9 align-items-center" id="step2">
                    <div class="col-lg-6 col-md-12 text-center text-lg-left mb-4 mb-lg-0">
                        <img src="img/content/tab-content-04.png" alt="..." />
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="pl-lg-5">

                            <span class="icon-circle orange mb-4"><span class="text">02</span></span>
                            <h3 class="text-color-md-blue mb-4">Design solutions</h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. aut odit
                                aut fugit, sed quia consequuntur.</p>
                        </div>
                    </div>
                </div>

                <div class="row mb-2-9 mb-md-6 mb-lg-9 align-items-center flex-row-reverse" id="step3">
                    <div class="col-lg-6 col-md-12 text-center text-lg-right mb-4 mb-lg-0">
                        <img src="img/content/tab-content-02.png" alt="..." />
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="pr-lg-5">

                            <span class="icon-circle blue mb-4"><span class="text">03</span></span>
                            <h3 class="text-color-md-blue mb-4">Deliver results</h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. aut odit
                                aut fugit, sed quia consequuntur.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        <section class="bg-theme-solid">
              {/* <span class="right-shape d-none d-xl-block"><img src="img/content/object-01.png" /></span> */}
              <span class="left-shape"></span>
              <div class="container position-relative z-index-99">
  
                  <div class="w-md-85 w-lg-75 w-xl-50 text-center mx-auto mb-5">
                      <div class="badge badge-pill badge-white-soft mb-3">
                          <span>Contact us</span>
                      </div>
  
                      <h2 class="mb-0 text-white">We would love to hear from you and talk about your project</h2>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-md-10 col-lg-7">
                          <form class="quform newsletter-rounded" enctype="multipart/form-data" onSubmit={handleSubmit}>
                              <div class="quform-elements">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div class="quform-element">
                                              <div class="quform-input">
                                                  <input class="rounded-pill form-control" type="text" name="email" placeholder="Your Email Address" onChange={handleChange}/>
                                              </div>
                                          </div>
                                      </div>

                                      <div class="col-md-12">
                                          <div class="quform-submit-inner">
                                              <button class="btn btn-white text-primary m-0 px-4" type="submit"><i class="fas fa-paper-plane"></i></button>
                                          </div>
                                          <div class="quform-loading-wrap"><span class="quform-loading"></span></div>
                                      </div>
                                  </div>
  
                              </div>
  
                          </form>
                          {/* <p class="text-white opacity8 mb-0 pt-2 text-center">Enter your e-mail.</p> */}
                      </div>
                  </div>
              </div>
          </section>

        <footer class="bg-white py-6">
          {/* <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4 col-xl-5 mb-4 mb-lg-0 text-center text-lg-left">
                <img src="img/logos/ObjectiveBitsLogo.svg" alt="..." class="mb-4" />
                <p class="text-dark">Amava is website theme for social media marketing business.</p>
              </div>
              <div class="col-lg-12 text-center text-lg-right">
                <ul class="footer-nav-link mb-0">
                  <li><a href="#!">Web Solutions</a></li>
                  <li><a href="#!">Mobile Development</a></li>
                  <li><a href="#!" class="butn style-one fill small">Sign Up</a></li>
                </ul>
              </div>
            </div>
          </div> */}
          <div class="container">
            <div class="border-top border-color-light-black pt-4 mt-4">
              <div class="row">
                <div class="col-lg-6 mb-4 mb-lg-0 text-center text-lg-left">
                  <p class="pb-0 text-default">&copy; Copyright ObjectiveBits, LLC {year}. All Rights Reserved.</p>
                </div>
                <div class="col-lg-6 text-default text-right text-lg-right">
                  (602) 644-1425
                  {/*<ul class="footer-social-icons2">*/}
                  {/*  <li>*/}
                  {/*      <a href="#!"><i class="fab fa-facebook-f"></i></a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*      <a href="#!"><i class="fab fa-twitter"></i></a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*      <a href="#!"><i class="fab fa-dribbble"></i></a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*      <a href="#!"><i class="fab fa-linkedin-in"></i></a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </div>
              </div>
            </div>
          </div>
        </footer>
    </Fragment>
  );
}

export default App;
